import React, { useEffect, useState } from "react";
// import ReactGA from "react-ga";
import XmlToJson from "xml-js";

import "./App.css";
import Header from "./components/features/peruquiosco/Header/default";
import Footer from "./components/features/peruquiosco/Footer/default";
import Carousel from "./components/features/peruquiosco/Carousel/default";
import Content from "./components/features/peruquiosco/Content/default";
import SliderCovers from "./components/features/peruquiosco/Covers/default";
import ScrollUp from "./components/features/peruquiosco/_children/ScrollUp/ScrollUp";
import useScript from "./hooks/useScript";
import configPiano from "./piano/config";
// import { Route } from "wouter";
import DataTreatment from "pages/DataTreatment";
import { useCookies } from "react-cookie";
import TagManager from "react-gtm-module";
import Modal from "./components/features/peruquiosco/_children/Modal/Modal";

function App() {
  const [cookies, setCookie, removeCookie] = useCookies([
    "_pc_user_status",
    "_pc_uuid",
  ]);

  // Use script to load sandbox or prod pianoID
  useScript(
    process.env.REACT_APP_ENV === "PROD"
      ? `https://experience.piano.io/xbuilder/experience/load?aid=${process.env.REACT_APP_PIANO_AID}`
      : `https://sandbox.piano.io/xbuilder/experience/load?aid=${process.env.REACT_APP_PIANO_AID}`
  );
  configPiano(process.env.REACT_APP_PIANO_AID, handlerLoginSuccess);

  // Logs a user in and sets the user data.
  const [userData, setUserData] = useState({
    user: {
      uid: "",
    },
    logged: false,
    token: "",
  });

  const [showModal, setShowModal] = useState(true);

  // Set home state.
  const [pageView, setPageView] = useState("home");

  // Login success handler
  function handlerLoginSuccess(data: any) {
    setUserData({
      ...userData,
      user: data,
      logged: true,
      token: window?.tp?.pianoId.getToken(),
    });
  }

  // Function DataLayer push
  function pushDataLayer(suscriptor: string) {
    TagManager.dataLayer({
      dataLayer: {
        event: "pageview_pq",
        suscriptor: suscriptor,
      },
    });
  }

  // Return page data treatment (spanish: tratamiento datos)
  useEffect(() => {
    // setCookie("_pc_user_status", "");
    setCookie("_pc_uuid", "nologin-v");

    const tagManagerArgs = {
      gtmId: "GTM-NBFMKG4",
      // gtmId: "GTM-PK72WXN",
    };

    TagManager.initialize(tagManagerArgs);

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const pageParam = params.get("page");

    if (pageParam === "tratamiento-datos") {
      setPageView("tratamiento-datos");
    }
  }, []);

  // Logs out the user if logged out and verify exist user session for set data user in state.
  useEffect(() => {
    setTimeout(() => {
      if (window.tp?.pianoId.getUser() != null) {
        setUserData({
          ...userData,
          logged: true,
          user: window?.tp?.pianoId.getUser(),
          token: window?.tp?.pianoId.getToken(),
        });
      } else {
        pushDataLayer("no");
      }

      const search = window.location.search;
      const params = new URLSearchParams(search);
      const action = params.get("action");

      if (action === "logout") {
        window.tp.pianoId.logout();
        setTimeout(() => {
          window.location.href = "/";
        }, 500);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    if (userData.user.uid) {
      setCookie("_pc_uuid", userData.user.uid);

      var urlencoded = new URLSearchParams();
      urlencoded.append("call", "get_user_by_userid");
      urlencoded.append("userid", userData.user.uid);

      var requestOptions = {
        method: "POST",
        headers: {
          Authorization: "Basic YW5hLnBlc3NhZ25vQGdtYWlsLmNvbToxMjMyNTIw",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
        // redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_ENV === "PROD"
          ? "https://api.godigibee.io/pipeline/elcomercio/v1/pq-integrador"
          : "https://test.godigibee.io/pipeline/elcomercio/v1/pq-integrador",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const xml2JSON = JSON.parse(
            XmlToJson.xml2json(result, { compact: true })
          );

          if (!localStorage.getItem("pq-integrador")) {
            if (window.tp.pianoId.isUserValid()) {
              localStorage.setItem("pq-integrador", "resolve");
              window.location.reload();
            }
          }

          if (Object.keys(xml2JSON.member.products).length > 0) {
            pushDataLayer("si");
          } else {
            pushDataLayer("no");
          }
        })
        .catch((error) => console.log("error", error));
    }
  }, [userData]);

  return (
    <div className="App">
      {pageView === "home" ? (
        <>
          <Header user={userData} setUser={setUserData} />
          <div id="home" />
          <Carousel />
          <SliderCovers user={userData} />
          <div id="subscriptions" />
          {/* <Content /> */}
          <Footer />
          <ScrollUp />
          <Modal show={showModal} setShow={setShowModal}>
            <a href="https://elcomercio.pe/suscripciones/promociones/?utm_source=pop-up-pq&utm_medium=pop-up-pq&utm_campaign=summer-sale" target="_blank">
              <picture>
                <source media="(max-width: 799px)" srcSet="https://cdna.elcomercio.pe/resources/dist/elcomercio/images/popup-ec-summer-sale-mobile-v2.gif" />
                <source media="(min-width: 800px)" srcSet="https://cdna.elcomercio.pe/resources/dist/elcomercio/images/popup-ec-summer-sale-desktop-v2.gif" />
                <img src="https://cdna.elcomercio.pe/resources/dist/elcomercio/images/popup-ec-summer-sale-desktop-v2.gif" alt="Banner Summer Sale" />
              </picture>
            </a>
          </Modal>
        </>
      ) : (
        <>
          <Header user={userData} setUser={setUserData} />
          <DataTreatment />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;
