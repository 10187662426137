import React, {FC, ReactNode, useEffect, useState} from "react";
import styled from "styled-components";
import Button from "../Button/Button";

interface ModalProps {
  children: ReactNode,
  show: boolean,
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

const WrapModal = styled.div`
  background-color: rgba(0, 0, 0, .8);
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;
  justify-content: center;
  display: flex;
  align-items: center;
`;

const BackgroundModal = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const ContentModal = styled.div`
  position: relative;
  margin: 0 auto;
  border-radius: 8px;
  max-height: 90vh;
  padding: 0px;

  img{
    width: 100%;
  }

  @media (max-width: 767px) {
    width: 85%;
  }

  @media (max-width: 798px) {
    @media screen and (orientation:landscape) {
      overflow-y: scroll;
      overflow-x: hidden;
    }
  }

  @media (max-width: 1000px) {
    @media screen and (orientation:landscape) {
      border-radius: 0px;
      padding: 0px;
      height: auto;
      width: 45%;
      max-height: 100%;
    }
  }
`

const ButtonClose =  styled.button`
  width: 30px;
  height: 30px;
  background: #ffcb05;
  color: #000000;
  font-weight: bold;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: 0px;
  position: absolute;
  top: -12px;
  right: -12px;
  font-size: 14px;

  @media (max-width: 798px) {
    @media screen and (orientation:landscape) {
      font-size: 18px;
      top: 3px;
      right: 3px;
    }
  }
`

const Modal: FC<ModalProps> = ({children, show, setShow}: ModalProps) => {

  return (
    show ? (
      <WrapModal>
        <BackgroundModal onClick={() => setShow(false)} />
        <ContentModal>
          <ButtonClose onClick={() => setShow(false)} >&#x2715;</ButtonClose>
          {children}
        </ContentModal>
      </WrapModal>
    ) : null
  )
}

export default Modal;
