import Button, { Theme } from "../_children/Button/Button";
import Link from "../_children/Link/Link.style";
import {
  Wrap,
  WrapContent,
  NavContent,
  Menu,
  Bars,
  LoggedWelcome,
} from "./header.styled";
import Layout from "../_children/Layout/Layout.style";
import AutoTest from "../../../../pages/AutoTest";
import logoBlack from "assets/images/logo-black.png";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useCookies } from "react-cookie";

// Declares a global interface window
declare global {
  interface Window {
    tp: any;
  }
}

const HeaderContent = ({ expanded = false, user, setUser }: any) => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "_pc_user_status",
    "_pc_uuid",
  ]);
  
  // Handle show Login piano auth.
  const handleLoginPianoAuth = () => {
    window.tp.pianoId.show();
  };

  // Handler show Register piano auth
  const handleRegisterPianoAuth = () => {
    window.tp.pianoId.show({
      screen: "register",
    });
  };

  // Handler of logout piano session, visor session and PQ1.
  const handlePianoLogout = () => {
    window.tp.pianoId.logout();
    setUser({ ...user, logged: false, token: "" });

    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    let urlencoded = new URLSearchParams();
    urlencoded.append("token", "false");

    fetch(`${process.env.REACT_APP_SERVICE_BASEPATH}/receiver.php?v4589`, {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    });

    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.onload = function () {
      document.location.reload();
    };
    iframe.src = "https://visor.peruquiosco.pe/account/logoff";
    document.body.appendChild(iframe);

    // setCookie("_pc_user_status", "");
    setCookie("_pc_uuid", "nologin-v");
    localStorage.removeItem("pq-integrador");
  };

  return (
    <NavContent expanded={expanded}>
      <div>
        <Menu direction="horizontal">
          <li>
            <Link href="/#home">
              <FontAwesomeIcon style={{ marginRight: "6px" }} icon={faHome} />
              Inicio
            </Link>
          </li>
          {/* <li>
            <Link href="/#subscriptions">Suscripciones</Link>
          </li> */}
        </Menu>
      </div>
      <Layout.CenterItems>
        {user.logged ? (
          <LoggedWelcome>
            <div
              style={{
                marginRight: "10px",
              }}
            >
              Bienvenido(a){" "}
              {`${user?.user?.firstName?.slice(0, 7)} ${
                user?.user?.firstName?.length > 7 ? "..." : ""
              }`}
            </div>
            <br />
            <Button onClick={handlePianoLogout}>Cerrar Sesión</Button>
          </LoggedWelcome>
        ) : (
          <>
            <Button onClick={handleRegisterPianoAuth}>Regístrate</Button>
            <Button onClick={handleLoginPianoAuth} margin="0 0 0 10px">
              Ingresa
            </Button>
          </>
        )}
      </Layout.CenterItems>
    </NavContent>
  );
};

// Returns a component Header with params of user.
const Header = ({ user, setUser }: any) => {
  const [expanded, setExpanded] = useState(false);
  const [showAutoTest, setShowAutoTest] = useState(false);

  const handleCloseAutoTest = () => setShowAutoTest(false);

  return (
    <Wrap>
      <WrapContent>
        <button className="btn__autoTest" onClick={() => setShowAutoTest(true)}>
          Pruebas Automatizadas
        </button>
        {showAutoTest && <AutoTest onClose={handleCloseAutoTest} />}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img src={logoBlack} alt="logo Perú Quiosco" />
          <Bars theme={Theme.White} onClick={() => setExpanded(!expanded)}>
            <FontAwesomeIcon size="lg" color="#868787" icon={faBars} />
          </Bars>
        </div>
        <HeaderContent expanded={expanded} user={user} setUser={setUser} />
      </WrapContent>
    </Wrap>
  );
};

export default Header;
